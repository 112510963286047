body {
  overflow-x: hidden;
  max-width: 2560px;
  margin: 0 auto;
}

/* – – – Scrollbar – – – */
/* Width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  z-index: 9999;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Slider */
.imageslider__item {
  clip-path: polygon(20px 0, 100% 0, 220px 100%, 0 100%);
}

/* Section Hero */
/* Desktop */
@media screen and (min-width: 992px) {
  .sectionhero__graphic.vyrobky {
    clip-path: polygon(60px 0, 100% 0, 100% 100%, 0 100%);
  }
  .sectionhero__graphic__bottombar {
    clip-path: none;
  }
}
/* Tablet + Mobile */
@media screen and (max-width: 991px) {
  .sectionhero__graphic.vyrobky {
    clip-path: none;
  }
  .sectionhero__graphic__bottombar {
    clip-path: polygon(2px 0, 100% 0, 100% 100%, 0 100%);
  }
}

/* Section Hero */
/* Desktop */
@media screen and (min-width: 992px) {
  .sectionhero__graphic.kontakty {
    clip-path: polygon(110px 0, 100% 0, 100% 100%, 0 100%);
  }
}
/* Tablet + Mobile */
@media screen and (max-width: 991px) {
  .sectionhero__graphic.kontakty {
    clip-path: polygon(2px 0, 100% 0, 100% 100%, 0 100%);
  }
}

/* Map */
/* Desktop */
@media screen and (min-width: 992px) {
  .sectionmap__mapwrapper {
    clip-path: polygon(150px 0, 100% 0, 100% 100%, 0 100%);
  }
}
/* Tablet + Mobile */
@media screen and (max-width: 991px) {
  .sectionmap__mapwrapper {
    clip-path: none;
  }
}

/* Section Hero */
/* Desktop */
@media screen and (min-width: 992px) {
  .sectionhero__graphic.reference {
    clip-path: polygon(60px 0, 100% 0, 100% 100%, 0 100%);
  }
}
/* Tablet + Mobile */
@media screen and (max-width: 991px) {
  .sectionhero__graphic.reference {
    clip-path: polygon(2px 0, 100% 0, 100% 100%, 0 100%);
  }
}

/* Default to hide all language-specific content */
.show--cs,
.show--en,
.show--de {
  display: none;
}

/* Show content based on the body class */
body.lang-cs .show--cs {
  display: block;
}

body.lang-en .show--en {
  display: block;
}

body.lang-de .show--de {
  display: block;
}

/* Pylony Totemy – Site images */
/* Desktop */
@media screen and (min-width: 992px) {
  .image--clipped.right {
    clip-path: polygon(80px 0, 100% 0, 100% 100%, 0 100%);
  }
}
/* Tablet + Mobile */
@media screen and (max-width: 991px) {
  .image--clipped.right {
    clip-path: none;
  }
}

.arrow__icon,
.imageslider__item,
.disable-text-selection,
.arrow__circle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
